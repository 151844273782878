





















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import moment from 'moment'
import { getDicts } from '@/utils/dict'
// import Dialog from '@/components/Dialog/index.vue'
import {
  Select,
  Option,
  DatePicker,
  Checkbox,
  Upload,
  Autocomplete,
  Dialog,
  CheckboxGroup
} from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(CheckboxGroup)

@Component
export default class extends Vue {
  // 字典数据
  // 区域
  areaDicts: any = []
  // 车辆类型
  vehicleTypeDicts: any = []
  // 车位类型
  carportTypeDicts: any = []
  // 绑定的车位选项
  carPlaceLists: any = []
  // 输入建议
  // 房屋
  houseDevice: any = []
  carPlaceListsCopy: any = []
  islong = false
  checkAll = false
  carInfo = {
    area: '',
    carNo: '',
    remark: '',
    carOwner: '',
    phone: '',
    carType: '',
    dueDate: '',
    carPlaceNo: '',
    carPlaceType: null,
    licensePhoto: '',
    houseId: '',
    houseInfo: '',
    devGroupIds: [] as any
  }
  pickerOptions = {
    disabledDate(v: any) {
      return v.getTime() < new Date().getTime() - 86400000
    }
  }
  // 提示框
  isShow = false
  carInfoRules = {
    // area: [{ required: true, message: '请选择区域', trigger: 'change' }],
    carNo: [
      { required: true, message: '请填写车牌号', trigger: 'blur' },
      {
        pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[A-Z])|([A-Z]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/,
        message: '请填写正确的车牌号',
        trigger: 'blur'
      }
    ],
    remark: [{ max: 200, message: '长度在200个字符', trigger: 'blur' }],
    carOwner: [
      // { required: true, message: '请填写车主真实姓名', trigger: 'blur' },
      { max: 20, message: '长度在20个字符', trigger: 'blur' }
    ],
    phone: [
      // { required: true, message: '请填写车主真实联系方式', trigger: 'blur' },
      {
        pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
        message: '请填写正确的手机号码',
        trigger: 'blur'
      }
    ],
    houseInfo: [
      { required: false, message: '请输入所属房屋', trigger: 'blur' }
    ],
    carType: [{ required: true, message: '请选择车辆类型', trigger: 'change' }],
    dueDate: [
      {
        required: !this.islong,
        message: '请选择日期',
        trigger: 'change'
      }
    ],
    carPlaceNo: [
      {
        required: false,
        message: '请填写绑定车位',
        trigger: 'blur'
      }
    ],
    carPlaceType: [
      {
        required: false,
        message: '请选择',
        trigger: 'blur'
      }
    ],
    area: [
      {
        required: false,
        message: '请选择',
        trigger: 'blur'
      }
    ]
    // licensePhoto: [{ required: true, message: '请上传行驶证照片' }]
  }
  isShowDialog = false
  loading = false
  // 输入建议
  restaurants: any = []
  //显示图片
  showLicensePhoto: any = ''
  // 编辑车辆的id
  editCarId: any = ''
  infoLoading = false
  $constants: any

  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }

  // 监听器
  @Watch('islong')
  getislong(val: boolean) {
    this.carInfoRules.dueDate = [
      {
        required: !val,
        message: '请选择日期',
        trigger: 'change'
      }
    ]
  }

  async beforeCreate() {
    // 获取相关字典
    // 区域
    this.areaDicts = await getDicts('PHASE')
    // 车辆类型
    this.vehicleTypeDicts = await getDicts('CAR_TYPE')
    this.carportTypeDicts = await getDicts('CAR_PLACE_TYPE')
    this.carportTypeDicts.map((item: any) => {
      if (item.value) {
        item.code = Number(item.value)
      }
    })
    CommonModule.GetDeviceGroups()
    if (this.$route.query.carNo) {
      this.editCarId = this.$route.query.carNo
      this.getCarInfo()
    }
  }

  async mounted() {
    this.houseDevice = await this.loadAllHouse()
  }

  carNoChange(v: any) {
    if (v) {
      this.carInfo.carNo = v.toUpperCase()
    }
  }

  // 获取车辆信息
  getCarInfo() {
    this.infoLoading = true
    this.$api.car
      .getCarInfo(this.$route.query.carNo)
      .then(async (res: any) => {
        this.infoLoading = false
        if (res.data.success) {
          const data = res.data.data
          this.carInfo = {
            area: data.area || '',
            carNo: data.carNo || '',
            remark: data.remark || '',
            carOwner: data.carOwner || '',
            phone: data.phone || '',
            carType: data.carType + '' || '',
            dueDate: data.dueDate
              ? moment(data.dueDate).format('YYYY-MM-DD')
              : '',
            carPlaceNo: data.carPlaceNo || '',
            carPlaceType: data.carPlaceType,
            licensePhoto: data.licensePhoto || '',
            houseId: data.houseId || '',
            houseInfo: data.houseInfo || '',
            devGroupIds: data.devGroupIds || []
          }
          this.carTypeChange(data.carType + '')
          if (data.licensePhoto) {
            this.showLicensePhoto =
              this.$constants.common.imgPrefix + res.data.data.licensePhoto
          }
          if (!data.dueDate) {
            this.islong = true
          }
          this.checkAll =
            this.carInfo.devGroupIds.length === this.deviceGroups.length
          if (this.carInfo.area !== '') {
            this.houseDevice = await this.loadAllHouse(this.carInfo.area)
            await this.loadAllCarport()
          }
        }
      })
      .catch(() => {
        this.infoLoading = false
      })
  }

  dialogConfirm() {
    this.isShow = false
  }

  closeDialog() {
    this.isShow = false
    this.carInfo.carPlaceNo = ''
  }

  carPlaceNoChange(val: any) {
    const temp = this.carPlaceListsCopy.filter(
      (item: any) => item.carPlaceNo === val
    )
    if (temp && temp.length > 0) {
      if (temp[0].status === 1) {
        this.isShow = true
      }
    }
  }
  // 车辆类型选择
  carTypeChange(val: string) {
    this.carInfoRules.area = [
      {
        required: val === '0' || val === '1' ? true : false,
        message: '请选择区域',
        trigger: 'blur'
      }
    ]
    this.carInfoRules.houseInfo = [
      {
        required: val === '0' || val === '1' ? true : false,
        message: '请输入所属房屋',
        trigger: 'blur'
      }
    ]
  }

  carPlaceNoFilter(val: any) {
    if (val) {
      this.carPlaceLists = this.carPlaceListsCopy.filter(
        (item: any) =>
          !!~item.carPlaceNo.indexOf(val) || !!~item.houseNo.indexOf(val)
      )
    } else {
      this.carPlaceLists = this.carPlaceListsCopy
    }
  }

  // 获取车位列表
  async getCarportList() {
    this.houseDevice = await this.loadAllHouse(this.carInfo.area)
    this.$api.car
      .getCarportList({
        size: '-1',
        area: this.carInfo.area,
        carPlaceType: this.carInfo.carPlaceType,
        houseNo: this.carInfo.houseInfo
      })
      .then((res: any) => {
        const data = res.data
        this.carPlaceListsCopy = data.data
        this.carPlaceLists = this.carPlaceListsCopy
      })
  }

  // 新增车辆
  addSave() {
    ;(this.$refs['carInfo'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        if (this.editCarId) {
          this.$api.car
            .editCarInfo({
              id: this.$route.query.carNo,
              ...this.carInfo,
              carPlaceType: this.carInfo.carPlaceType,
              dueDate: this.islong
                ? ''
                : moment(this.carInfo.dueDate).format('YYYY-MM-DD 00:00:00')
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('保存成功')
                this.goback()
              } else {
                this.$message.error(data.msg)
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.$api.car
            .addCar({
              ...this.carInfo,
              carPlaceType: this.carInfo.carPlaceType,
              dueDate: this.islong
                ? ''
                : moment(this.carInfo.dueDate).format('YYYY-MM-DD 00:00:00')
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('新增成功')
                this.goback()
              } else {
                this.$message.error(data.msg)
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      } else {
        return false
      }
    })
  }

  // 上传成功
  uploadSuccess(res: any) {
    this.carInfo.licensePhoto = res.data
  }

  handleAvatarChange(file: any) {
    this.showLicensePhoto = URL.createObjectURL(file.raw)
  }
  // 上传前
  beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    const isPNG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG && !isJPEG && !isPNG) {
      this.$message.error('上传照片只能是 JPG/JPEG/PNG 格式!')
    }
    if (!isLt2M) {
      this.$message.error('上传单张照片大小不能超过 2MB!')
    }
    return (isJPG || isJPEG || isPNG) && isLt2M
  }

  // 获取所有房屋
  async loadAllHouse(phase?: string) {
    const sourceData: any = []
    await this.$api.resident
      .getHouseList({ phase, size: -1 })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          data.data.map((v: any) => {
            sourceData.push({
              id: v.id,
              value: v.buildingNo,
              owner: v.ownerName
            })
          })
        }
      })
    return sourceData
  }

  // 获取所有车位
  async loadAllCarport() {
    this.$api.car
      .getCarportList({
        size: '-1',
        area: this.carInfo.area,
        carPlaceType: this.carInfo.carPlaceType,
        houseNo: this.carInfo.houseInfo
      })
      .then((res: any) => {
        const data = res.data
        this.carPlaceListsCopy = data.data
        this.carPlaceLists = this.carPlaceListsCopy
      })
  }

  querySearch(queryString: string, cb: any, restaurants: any) {
    const results = queryString
      ? restaurants.filter(this.createFilter(queryString))
      : restaurants
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter(queryString: any) {
    return (restaurant: any) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
      )
    }
  }

  handleInputBlue(value: any) {
    if (value !== '') {
      const results = this.houseDevice.filter(
        (item: any) => item.value === value + ''
      )
      if (results && results.length !== 0) {
        this.carInfo.houseId = results[0].id
        this.carInfo.houseInfo = results[0].value
        this.getHouseDevices(results[0].id)
        this.loadAllCarport()
      } else {
        this.carInfo.houseInfo = ''
        this.carInfo.houseId = ''
        this.carInfo.devGroupIds = []
      }
    }
  }

  handleInputSelect(item: any) {
    setTimeout(() => {
      ;(this.$refs['carInfo'] as any).validate()
    }, 100)
    this.carInfo.houseId = item.id
    this.carInfo.houseInfo = item.value
    this.loadAllCarport()
    this.getHouseDevices(item.id)
  }

  // 获取绑定房屋的设备组
  async getHouseDevices(id: any) {
    await this.$api.resident.getHouseInfo(id).then((res: any) => {
      const data = res.data
      if (data.success) {
        if (data.data.houseDevices.length === 0) {
          this.carInfo.devGroupIds = []
        } else {
          data.data.houseDevices.map((v: any) => {
            this.carInfo.devGroupIds.push(v.deviceGroupId)
          })
        }
      }
    })
  }

  handleCheckAllChange(val: boolean) {
    this.carInfo.devGroupIds = val
      ? this.deviceGroups.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedDoorsChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.deviceGroups.length
  }

  // 返回
  goback() {
    this.$router.push('/cars/vehicle-list')
  }
}
